/**
 * All Drimify Tokens.
 *
 * This is the only place where you should list Drimiify Tokens in Javascript.
 *
 * Utility methods generate the Typescript Interface from this list.
 */
export const DrimifyCustomTokenKeys = {
  '--ds-leaderboard-cell-user-highlight': undefined,
  '--ds-leaderboard-cell-change-highlight': undefined,
} as const;

////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY BELOW THIS LINE
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Generates Drimify Tokens.
 */
export const GenerateDrimifyCustomTokens = (custom?: DrimifyCustomTokens) => {
  return custom || undefined;
};

/**
 * Helper typescript syntax for mapping keys from a constant to a type.
 */
type MappedInterface<T> = {
  [K in keyof T]?: string;
};

/**
 * Generated Interface for the Drimify Tokens.
 */
export interface DrimifyCustomTokens extends Partial<MappedInterface<typeof DrimifyCustomTokenKeys>> {}
